<template lang="pug">
div(v-if="loaded")
  ListSchedule(
    :items="productionInstances"
    @select="select"
  )
</template>

<script>
import ListSchedule from './list'

export default {
  components: { ListSchedule },
  data: () => ({}),
  computed: {
    productionInstances () {
      return this.$store.getters['productionInstances/items']
    },
    loaded () {
      return this.$store.state.productionInstances.loaded
    }
  },
  methods: {
    select (item) {
      // bring up a PI detail
      this.$router.push({ path: '/jobs/' + item.job })
    }

  },
  async created () {
    await this.$store.dispatch('dashboards/_LOAD_PREFLIGHT')
  }
}
</script>
