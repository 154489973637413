<template lang="pug">
div
  v-toolbar(flat fixed)
    v-btn(
      @click='toggleDrawer'
      small
      flat
      )
      v-icon() menu
    div.display-1.font-weight-bold preflight
  v-list(dense two-line).pa-0
    div(
      v-for='(item, i) in itemsSorted'
      :key='i'
    )
      v-list-tile(
        v-if="item.schedType === 'line'"
        avatar
      ).list-item
        v-list-tile-avatar(v-if="item.dashboard.image.url" tile @click="select(item)")
          img(:src="item.dashboard.image.url")
        v-list-tile-content(@click="select(item)")
          v-list-tile-title
            v-layout(row)
              v-flex(xs6) {{item.dashboard.designName}} - {{item.dashboard.jobSubtitle}} -
                span.font-weight-bold  {{item.location.template.name}}
              v-flex(xs2)
                v-layout(row)
                  v-flex.pink--text(xs12 v-if="item.minutes.override")
                    | minutes: {{item.minutes.override}}
                  v-flex(xs12 v-else)
                    | minutes: {{item.minutes.default}}
              v-flex(xs4)
                v-layout(row)
                  v-flex.pink--text(xs12)
                    | meta: {{item.meta}}
          v-list-tile-sub-title
            v-layout(row)
              v-flex(xs12)
                PiSubtitle(:item='item')

</template>
<script>
import scheduleSort from '../../../helpers/sort'
import PiSubtitle from '../../../components/pi-subtitle'
import JobProgressBar from '@/components/JobProgressBar'
import PiProgressBar from '@/components/PiProgressBar'
import { mapFields } from 'vuex-map-fields'

export default {
  components: { JobProgressBar, PiProgressBar, PiSubtitle },
  props: {
    items: { type: Array }
  },
  data: () => ({ priorityToggle: false, teamToggle: false, progressToggle: false }),
  computed: {
    ...mapFields('productionInstances', {
      detail: 'detail'
    }),
    itemsSorted () {
      const sort = scheduleSort(this.items)
      return sort.filter(x => {
        return x.schedType !== 'team' &&
        x.schedType !== 'date' &&
        !x.task.isTask
      })
    }
  },
  methods: {
    toggleDrawer () {
      this.$store.dispatch('core/toggleDrawer')
    },

    select (item) {
      this.$emit('select', item)
    }

  }
}
</script>
